import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { DataItemDiagComponent } from '../../../common/entity/data-item-diag/data-item-diag.component';
import { EntitySelectorDiagComponent } from '../../../common/entity-selector-diag/entity-selector-diag.component';
import { WorkflowService } from '../../../services/workflow.service';
import { NotificationService } from '../../../services/notification.service';
import { EntityService } from '../../../services/entity.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoginService } from '../../../services/login.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-select-endata-for-workflow-dialog',
  templateUrl: './select-endata-for-workflow-dialog.component.html',
  styleUrls: ['./select-endata-for-workflow-dialog.component.css'],
})
export class SelectEndataForWorkflowDialogComponent implements OnInit {
  entityName = '';
  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private matDialog: MatDialog,
    private matDialogRef: MatDialogRef<SelectEndataForWorkflowDialogComponent>,
    private workflowService: WorkflowService,
    private notificationService: NotificationService,
    private entityService: EntityService,
    private loginService: LoginService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.getEntityName();
  }

  openCreationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: 'new',
      entityCode: this.data.entityChoice,
    };
    this.workflowService.createNewRecordForWorkflow = true;
    const dialogRef = this.matDialog.open(DataItemDiagComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.workflowService
          .assignEntdataToWorkflow(this.data.workflowStepId, result)
          .pipe(take(1))
          .subscribe((res) => {
            if (res) {
              this.notificationService.showSnackbarMessage(
                'Messages.assignEntdataToWorkflowSuccess'
              );
              this.matDialogRef.close('success');
            }
          });
      }
    });
  }

  openSelectionDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: this.data.entityChoice,
    };

    const dialogRef = this.matDialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.workflowService
          .assignEntdataToWorkflow(this.data.workflowStepId, result.id)
          .pipe(take(1))
          .subscribe((res) => {
            if (res) {
              this.notificationService.showSnackbarMessage(
                'Messages.assignEntdataToWorkflowSuccess'
              );
              this.matDialogRef.close('success');
            }
          });
      }
    });
  }

  private getEntityName() {
    this.entityService.storedEntities$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((entities) => {
        const entity = entities.find(
          (entity) => entity.entity_code === this.data.entityChoice
        );
        this.entityName =
          this.loginService.getLoginUser().lang === 'gr'
            ? entity.name_gr
            : entity.name_en;
      });
  }
}
